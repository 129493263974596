import React, { FC } from 'react'
import Image from 'next/image'
import styles from './BannerBlock.module.scss'
import cn from "classnames";

type BannerBlockProps = {
  containerScaling?: string
  errorPage?: boolean
}

const BannerBlock:FC<BannerBlockProps> = ({containerScaling, errorPage}) => {
  return (
    <section className={cn(styles.sectionBanner, containerScaling, {[styles.errorPageContainer]:errorPage})}>
      <div className={styles.sectionBannerContainer}>
        <div className={styles.sectionBannerInner}>
          <div className={styles.sectionBannerContent}>
            <div className={styles.sectionBannerText}>
              <span>Track</span> your pills, <span>buy</span> extra
              supplements and be the first
              to know about the latest news
              about supplements
            </div>
            <div className={styles.sectionBannerFoot}>
              <div className={styles.apps}>
                <a
                  className={styles.appsLink}
                  href="https://apps.apple.com/us/app/vitaliv-club/id1611704943"
                >
                  <span className={styles.appsImg}>
                    <Image
                      src="/images/aboutUs/appstore1.svg"
                      alt="appstore"
                      width={150}
                      height={50}
                      objectFit="contain"
                    />
                  </span>
                </a>
                <a
                  className={styles.appsLink}
                  href="https://play.google.com/store/apps/details?id=us.vitaliv"
                >
                  <span className={styles.appsImg}>
                    <Image
                      src="/images/aboutUs/googleplay1.svg"
                      alt="play market"
                      width={150}
                      height={50}
                      objectFit="contain"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.sectionBannerPic}>
            <Image
              src="/images/aboutUs/phone1.png"
              alt="phone"
              width={428}
              height={615}
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerBlock
